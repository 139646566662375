import * as React from "react";
import { Link } from "gatsby";

const Navigation = () => {
    return(
        <>
        <nav>
            <Link to='/'><span className="nav-link">← Home</span></Link>
        </nav>
        </>
    )
}

export default Navigation;