import * as React from "react";

const Footer = () => {
    return(
        <>
        <footer className="section-wrapper footer">
            <a href="https://github.com/shane-ducksbury">Designed and built by Shane Ducksbury</a>
        </footer>
        </>
    )
}

export default Footer;