import * as React from "react"
import { graphql } from "gatsby"
import Navigation from "../navigation"
import Footer from "../footer"

const CONTENT_URL = "https://content.shaneducksbury.com"

const Project = ({ data }) => {
    const title = data.strapiProject.Title
    const githubLink = data.strapiProject.GithubLink
    const description = data.strapiProject.Description
    const tools = data.strapiProject.Tools.split(",");
    const demoLink = data.strapiProject.DemoLink
    const projectScreenshotUrl = data.strapiProject.ProjectScreenshot.url
    const overview = data.strapiProject.Overview.data.Overview.split("\n")
    const background = data.strapiProject.Background.data.Background.split("\n")
    const solution = data.strapiProject.Solution.data.Solution.split("\n")

    const generateParagraph = (data, heading) => {
        return (
            <>
            <h3>{heading}</h3>
            {data.map(paragraph => {
                return(<p>{paragraph}</p>)
            })}
            </>
        )
    }

    return(
        <div className="site-wrapper">
            <Navigation />
            <main className="single-project-wrapper">
                <div className="single-project-hero-image">
                    <img src={CONTENT_URL + projectScreenshotUrl} alt="" className="project-image" />
                </div>
                <h1>{title}</h1>
                <ul className="project-tool-list">
                        {tools.map((tool, index) => {
                            return(<li className="tool-text" key={index}>{tool}</li>)
                        })}
                    </ul>
                    <div className="single-project-links">
                        {githubLink ? 
                            <a href={githubLink} target="_blank">See It On GitHub</a>
                        : null}
                        {demoLink ? 
                            <a href={demoLink} target="_blank">Visit the Live Demo</a>
                        : null }
                    </div>
                {overview[0] !== 'empty' ? generateParagraph(overview, 'Overview') : null}
                {background[0] !== 'empty' ? generateParagraph(background, 'Background') : null}
                {solution[0] !== 'empty' ? generateParagraph(solution, 'Solution') : null}
            </main>
            <Footer />
        </div>
    )
}

export default Project;

export const pageQuery = graphql `query MyQuery($id: String) {
    strapiProject(id: {eq: $id}) {
      ProjectScreenshot {
        url
      }
      Overview {
        data {
            Overview
        }
      }
      Background {
        data {
            Background
        }
      }
      Solution {
        data {
            Solution
        }
      }
      GithubLink
      Title
      Description
      DemoLink
      Tools
    }
  }`